import React from 'react'

function Button(props) {
  return (
    <div className={"text-3xl " + props.className}>
        <button onClick={props.onClick} className='flex justify-center items-center bg-dab-blue rounded-full h-12 w-full p-3 text-white font-mont font-semibold mt-1 mb-5 transition ease-in-out duration-250 hover:bg-sky-800'>{props.children}</button>
    </div>
  )
}


export default Button