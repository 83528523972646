import React from 'react'

function Input(props) {
  return (
    <div className={'flex-1 ' + props.className}>
        <p>{props.label}</p>
        <input id={props.id} type={props.type ? props.type : "text"} defaultValue={props.value ? props.value : ""} className='border border-dab-grey rounded-full h-12 w-full mt-1 mb-5 p-3'></input>
    </div>
  )
}


export default Input