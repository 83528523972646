import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import {useNavigate} from 'react-router-dom'; 
import {LoginCall} from './../apiCalls';
import { useState } from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import Error from '../Error/Error';
import { doc } from 'prettier';

function Login(props) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setInvalid] = useState(false);

  const attemptLogin = async() => {
    if(!isLoading){
      let payload = {
        'email':document.getElementById("email").value,
        'pass':document.getElementById("password").value
      }

      setIsLoading(true);
      let loginStatus = await(LoginCall(payload));
      console.log(loginStatus);
      setIsLoading(false);
      if(!loginStatus.Status){
        window.name = loginStatus.Name; 
        window.listingID = loginStatus.ListingID;
        window.email = loginStatus.ListingEmail;
        window.phone = loginStatus.Phone;
        window.street = loginStatus.Street;
        window.city = loginStatus.City;
        window.state = loginStatus.State;
        window.zip = loginStatus.Zip;
        window.salesRadius = loginStatus.SalesRadius;
        window.quotesThisMonth = loginStatus.QuotesThisMonth;
        window.totalQuotes = loginStatus.TotalQuotes;
        window.isLoggedIn = true;
        navigate('/Dashboard')
      } else {
        setInvalid(true)
      }
  } else {return}

  }

  return (
    <div className='flex container sm:items-center sm:justify-center sm:mt-20'>
      
      <Container title="Login" className="sm:max-w-4xl">
        {isInvalid && (<Error text="Invalid Login Credentials. Please Try Again."/>)}
        <Input id="email" label="Email"/>
        <Input id="password" type="password" label="Password"/>
        <Button title="" onClick={attemptLogin}>{isLoading ? <SyncLoader color='white'/> : "Submit"}</Button>
        <p className='underline text-center hover:cursor-pointer' onClick={() => GoToRoute("CreateAccount")}>
          Or Create an Account
        </p>
      </Container>
    </div>
  )

  function GoToRoute(r) {
    navigate('/' + r)
  }

}


export default Login
