import React from 'react'
import {useNavigate} from 'react-router-dom'; 

function Header(props) {
  return (
    <div className='w-full flex justify-center sm:justify-start bg-dab-blue text-white p-5 font-mont text-3xl font-bold'>
            <h1>Design-A-Building&trade;</h1>
    </div>
  )
}


export default Header
