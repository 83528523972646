import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Error from '../Error/Error'
import { CreateListingAccount } from './../apiCalls';
import SyncLoader from "react-spinners/SyncLoader";

function PaymentInformation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const [message, setMessage] = useState("");

  const CreateListingAccountCall = async() => {
    if(!isLoading) {
      let payload = {
        "Vendor-Email": window.email,
        "CardNumber": window.cardNum,
        "Cardholder": window.cardName,
        "ExpirationMonth": window.expMonth,
        "ExpirationYear": window.expYear,
        "CVC": window.cvc,
        "Password": window.password
      }
      setIsLoading(true);
      let createStatus = await(CreateListingAccount(payload));
      console.log(createStatus);
      setIsLoading(false);
      if(createStatus.Status == "Success"){
        window.listingID = createStatus.ListingID;
        window.customerID = createStatus.CustomerID;
        GoToRoute("CreateAccount/PaymentInfo/ListingInformation")
      } else {
        setInvalid(true);
        setMessage(createStatus.Status + ". Please Contact Support.");
      }
    }
  }

  const navigate = useNavigate()
  useEffect( () => {
    if(!window.email){
      navigate("/CreateAccount")
    }
  })
  return (
    <div className='flex container sm:items-center sm:justify-center sm:mt-20'>
      <Container title="Create Account" className="sm:max-w-4xl">
        {isInvalid && (<Error text={message}/>)}
        <Heading text="Payment Information"/>
        <Input id="cardName" label="Cardholder Name"/>
        <Input id="cardNum" label="Card Number"/>
        <div className='flex flex-row justify-center space-x-3'>
          <Input id="expMonth" label="Exp. Month" type="number"/>
          <Input id="expYear" label="Exp. Year" type="number"/>
          <Input id="cvc" label="CVC"/>
        </div>
        <Button title="" onClick={() => ValidateInputs()}>{isLoading ? <SyncLoader color='white'/> : "Next"}</Button>
      </Container>
    </div>
  )
  
  function ValidateInputs() {
    let inptName = document.getElementById("cardName");
    let inptCardNum = document.getElementById("cardNum")
    let inptExpMonth = document.getElementById("expMonth");
    let inptExpYear = document.getElementById("expYear");
    let inptCVC = document.getElementById("cvc");

    if(inptName.value == "" || inptCardNum.value == "" || inptExpMonth.value == "" || inptExpYear.value == "" || inptCVC.value == ""){
      setInvalid(true);
      setMessage("Fields cannot be empty.");
      return;
    }
    
    if(inptCardNum.value.length != 16 && inptCardNum.value.length != 19){
      setInvalid(true);
      setMessage("Invalid Card Number Format.");
      return;
    }

    SetValues();
  }

  function SetValues(){
    window.cardName = document.getElementById("cardName").value
    window.cardNum = document.getElementById("cardNum").value
    window.expMonth = document.getElementById("expMonth").value
    window.expYear = document.getElementById("expYear").value
    window.cvc = document.getElementById("cvc").value
    
    CreateListingAccountCall();
  }

  function GoToRoute(r) {
    navigate('/' + r)
  }
}


export default PaymentInformation
