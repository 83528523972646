import React from 'react'
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Container from '../Container/Container';
import { useState } from 'react';
import './FullviewQuote.css';

function FullviewQuote(props) {
  let colorHexChart = {
    "Ash Gray": {"Hex":"#C2BCAF", "Text":"black"},
    "Alamo White": {"Hex":"#e0e0e0", "Text":"black"},
    "Bare Galvalume": {"Hex":"9b9b9b", "Text":"black"},
    "Burgundy": {"Hex":"#552629", "Text":"white"},
    "Brown": {"Hex":"#563924", "Text":"white"},
    "Brite Red": {"Hex":"#c80c00", "Text":"white"},
    "Burnished Slate": {"Hex":"#555044", "Text":"white"},
    "Buckskin": {"Hex":"#a08c73", "Text":"white"},
    "Brilliant White": {"Hex":"#ffffff", "Text":"black"},
    "Charcoal": {"Hex":"#606165", "Text":"white"},
    "Copper Metallic": {"Hex":"#e7721d", "Text":"black"},
    "Dark Green": {"Hex":"#00412f", "Text":"white"},
    "Gallery Blue": {"Hex":"#26424d", "Text":"white"},
    "Hunter Green": {"Hex":"#3c4d3c", "Text":"white"},
    "Ivory": {"Hex":"#ff3bc3", "Text":"black"},
    "Light Stone": {"Hex":"#c2bca5", "Text":"black"},
    "Matte Black": {"Hex":"#181818", "Text":"white"},
    "Ocean": {"Hex":"#476474", "Text":"white"},
    "Pewter Gray": {"Hex":"#aea9a5", "Text":"black"},
    "Rustic Red": {"Hex":"#703229", "Text":"white"},
    "Taupe": {"Hex":"#a59b87", "Text":"black"},
    "Tan": {"Hex":"#d5b492", "Text":"black"}
  };
  const [colorsActive, setColorsActive] = useState(false);
  const [accessoriesActive, setAccessoriesActive] = useState(false);

  let colorsData = JSON.parse(props.quote.Colors.replaceAll("'",'"'))
  let colors = {
    "Wall":colorsData[0],
    "Wainscot":colorsData[1],
    "Trim": colorsData[2],
    "Roof":colorsData[3],
    "Roof Trim":colorsData[4],
    "Ridgecap":colorsData[5],
    "Soffit":colorsData[6],
    "Sliding Door":colorsData[7]
  }
  console.log(colors)
  let dims = JSON.parse(props.quote.Dimensions);
  let dimensions = {
    'Length':dims[0],
    'Width':dims[1],
    'Height':dims[2]
  };
  let roofData = JSON.parse((props.quote.Roof).replaceAll("'",'"'));
  let roof = {
    "Pitch":roofData[0],
    "Gable":roofData[1],
    "Eave":roofData[2]
  };

  let windowsQty = JSON.parse(props.quote.Windows);
  let walkDoorQty = JSON.parse(props.quote.WalkDoors);
  let overheadQty = JSON.parse(props.quote.OverheadDoors);
  let slidingQty = JSON.parse(props.quote.SlidingDoors);

  let windowDim = JSON.parse(props.quote.WindowDimensions.replaceAll("'", '"'));
  let walkDoorDim = JSON.parse(props.quote.WalkDoorDimensions.replaceAll("'", '"'));
  let overheadDim = JSON.parse(props.quote.OverheadDoorDimensions.replaceAll("'", '"'));
  let slidingDim = JSON.parse(props.quote.SlidingDoorDimensions.replaceAll("'", '"'));

  let northWindowsString = ""
  let westWindowsString = ""
  let southWindowsString = ""
  let eastWindowsString = ""

  let northWalksString = ""
  let westWalksString = ""
  let southWalksString = ""
  let eastWalksString = ""

  let northOverheadsString = ""
  let westOverheadsString = ""
  let southOverheadsString = ""
  let eastOverheadsString = ""

  let northSlidingsString = ""
  let westSlidingsString = ""
  let southSlidingsString = ""
  let eastSlidingsString = ""

  windowDim.North.forEach((window) => {
    northWindowsString += window.Dimensions + " (" + window.Qty + "), "
  })
  windowDim.West.forEach((window) => {
    westWindowsString += window.Dimensions + " (" + window.Qty + "), "
  })
  windowDim.South.forEach((window) => {
    southWindowsString += window.Dimensions + " (" + window.Qty + "), "
  })
  windowDim.East.forEach((window) => {
    eastWindowsString += window.Dimensions + " (" + window.Qty + "), "
  })

  walkDoorDim.North.forEach((walk) => {
    northWalksString += walk.Dimensions + " (" + walk.Qty + "), "
  })
  walkDoorDim.West.forEach((walk) => {
    westWalksString += walk.Dimensions + " (" + walk.Qty + "), "
  })
  walkDoorDim.South.forEach((walk) => {
    southWalksString += walk.Dimensions + " (" + walk.Qty + "), "
  })
  walkDoorDim.East.forEach((walk) => {
    eastWalksString += walk.Dimensions + " (" + walk.Qty + "), "
  })

  overheadDim.North.forEach((overhead) => {
    northOverheadsString += overhead.Dimensions + " (" + overhead.Qty + "), "
  })
  overheadDim.West.forEach((overhead) => {
    westOverheadsString += overhead.Dimensions + " (" + overhead.Qty + "), "
  })
  overheadDim.South.forEach((overhead) => {
    southOverheadsString += overhead.Dimensions + " (" + overhead.Qty + "), "
  })
  overheadDim.East.forEach((overhead) => {
    eastOverheadsString += overhead.Dimensions + " (" + overhead.Qty + "), "
  })

  slidingDim.North.forEach((sliding) => {
    northSlidingsString += sliding.Dimensions + " (" + sliding.Qty + "), "
  })
  slidingDim.West.forEach((sliding) => {
    westSlidingsString += sliding.Dimensions + " (" + sliding.Qty + "), "
  })
  slidingDim.South.forEach((sliding) => {
    southSlidingsString += sliding.Dimensions + " (" + sliding.Qty + "), "
  })
  slidingDim.East.forEach((sliding) => {
    eastSlidingsString += sliding.Dimensions + " (" + sliding.Qty + "), "
  })



  return (
    <div className='w-full flex flex-row text-dab-grey p-5 font-mont font-medium text-xl text-center'>
      <p className='w-1/6 mx-4 my-0 text-ellipsis overflow-clip'> {props.quote.UserEmail} </p>
      <p className='w-1/6 mx-4 my-0'> {"W: " + dimensions.Width + "' L: " + dimensions.Length + "' H: " + dimensions.Height + "'"} </p>
      <p className='w-1/6 mx-4 my-0'> {"Pitch: " + roof.Pitch + " Gable: " + roof.Gable + ' Eave: ' + roof.Eave} </p>
      <div className='w-1/6 mx-4 my-0 flex flex-col relative'>
        <Button classname='text-xl' onClick={() => {setAccessoriesActive(!accessoriesActive)}}>Click for Info</Button>
        {accessoriesActive && (
          <Container className="m-0 double" title="Accessories">
            <Heading text="Windows"/>
            <table className="table-fixed border-collapse border-2 border-dab-grey border-spacing-0">
                <tr><td className='border-2 border-dab-grey bg-dab-blue text-white'>Side</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Qty</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Dimensions</td></tr>
                <tr><td className='border-2 border-dab-grey'>North</td><td className={'border-2 border-dab-grey'}>{windowsQty[0]}</td><td className={'border-2 border-dab-grey'}>{northWindowsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>West</td><td className={'border-2 border-dab-grey'}>{windowsQty[1]}</td><td className={'border-2 border-dab-grey'}>{westWindowsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>South</td><td className={'border-2 border-dab-grey'}>{windowsQty[2]}</td><td className={'border-2 border-dab-grey'}>{southWindowsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>East</td><td className={'border-2 border-dab-grey'}>{windowsQty[3]}</td><td className={'border-2 border-dab-grey'}>{eastWindowsString}</td></tr>
            </table>
            <Heading text="Walkin Doors"/>
            <table className="table-fixed border-collapse border-2 border-dab-grey border-spacing-0">
                <tr><td className='border-2 border-dab-grey bg-dab-blue text-white'>Side</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Qty</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Dimensions</td></tr>
                <tr><td className='border-2 border-dab-grey'>North</td><td className={'border-2 border-dab-grey'}>{walkDoorQty[0]}</td><td className={'border-2 border-dab-grey'}>{northWalksString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>West</td><td className={'border-2 border-dab-grey'}>{walkDoorQty[1]}</td><td className={'border-2 border-dab-grey'}>{westWalksString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>South</td><td className={'border-2 border-dab-grey'}>{walkDoorQty[2]}</td><td className={'border-2 border-dab-grey'}>{southWalksString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>East</td><td className={'border-2 border-dab-grey'}>{walkDoorQty[3]}</td><td className={'border-2 border-dab-grey'}>{eastWalksString}</td></tr>
            </table>
            <Heading text="Overhead Doors"/>
            <table className="table-fixed border-collapse border-2 border-dab-grey border-spacing-0">
                <tr><td className='border-2 border-dab-grey bg-dab-blue text-white'>Side</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Qty</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Dimensions</td></tr>
                <tr><td className='border-2 border-dab-grey'>North</td><td className={'border-2 border-dab-grey'}>{overheadQty[0]}</td><td className={'border-2 border-dab-grey'}>{northOverheadsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>West</td><td className={'border-2 border-dab-grey'}>{overheadQty[1]}</td><td className={'border-2 border-dab-grey'}>{westOverheadsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>South</td><td className={'border-2 border-dab-grey'}>{overheadQty[2]}</td><td className={'border-2 border-dab-grey'}>{southOverheadsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>East</td><td className={'border-2 border-dab-grey'}>{overheadQty[3]}</td><td className={'border-2 border-dab-grey'}>{eastOverheadsString}</td></tr>
            </table>
            <Heading text="Sliding Doors"/>
            <table className="table-fixed border-collapse border-2 border-dab-grey border-spacing-0">
                <tr><td className='border-2 border-dab-grey bg-dab-blue text-white'>Side</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Qty</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Dimensions</td></tr>
                <tr><td className='border-2 border-dab-grey'>North</td><td className={'border-2 border-dab-grey'}>{slidingQty[0]}</td><td className={'border-2 border-dab-grey'}>{northSlidingsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>West</td><td className={'border-2 border-dab-grey'}>{slidingQty[1]}</td><td className={'border-2 border-dab-grey'}>{westSlidingsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>South</td><td className={'border-2 border-dab-grey'}>{slidingQty[2]}</td><td className={'border-2 border-dab-grey'}>{southSlidingsString}</td></tr>
                <tr><td className='border-2 border-dab-grey'>East</td><td className={'border-2 border-dab-grey'}>{slidingQty[3]}</td><td className={'border-2 border-dab-grey'}>{eastSlidingsString}</td></tr>
            </table>
          </Container>
        )}
      </div>
      <div className='w-1/6 mx-4 my-0 flex flex-col relative'>
        <Button classname='text-xl' onClick={() => {setColorsActive(!colorsActive)}}>Click for Info</Button>
        {colorsActive && (
          <Container className="m-0 double" title="Colors">
            <table className="table-fixed border-collapse border-2 border-dab-grey border-spacing-0">
                <tr><td className='border-2 border-dab-grey bg-dab-blue text-white'>Type</td><td className='border-2 border-dab-grey bg-dab-blue text-white'>Color</td></tr>
                <tr><td className='border-2 border-dab-grey'>Wall</td><td className={'border-2 border-dab-grey bg-' + colors['Wall'].replace(" ", "-") + " text-" + colorHexChart[colors['Wall']]['Text']}>{colors['Wall']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Wainscot</td><td className={'border-2 border-dab-grey bg-' + colors['Wainscot'].replace(" ", "-") + " text-" + colorHexChart[colors['Wainscot']]['Text']}>{colors['Wainscot']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Trim</td><td className={'border-2 border-dab-grey bg-' + colors['Trim'].replace(" ", "-") + " text-" + colorHexChart[colors['Trim']]['Text']}>{colors['Trim']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Roof</td><td className={'border-2 border-dab-grey bg-' + colors['Roof'].replace(" ", "-") + " text-" + colorHexChart[colors['Roof']]['Text']}>{colors['Roof']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Roof Trim</td><td className={'border-2 border-dab-grey bg-' + colors['Roof Trim'].replace(" ", "-") + " text-" + colorHexChart[colors['Roof Trim']]['Text']}>{colors['Roof Trim']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Ridgecap</td><td className={'border-2 border-dab-grey bg-' + colors['Ridgecap'].replace(" ", "-") + " text-" + colorHexChart[colors['Ridgecap']]['Text']}>{colors['Ridgecap']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Soffit</td><td className={'border-2 border-dab-grey bg-' + colors['Soffit'].replace(" ", "-") + " text-" + colorHexChart[colors['Soffit']]['Text']}>{colors['Soffit']}</td></tr>
                <tr><td className='border-2 border-dab-grey'>Sliding Door</td><td className={'border-2 border-dab-grey bg-' + colors['Sliding Door'].replace(" ", "-") + " text-" + colorHexChart[colors['Sliding Door']]['Text']}>{colors['Sliding Door']}</td></tr>
            </table>
            <div className='hidden bg-Ash-Gray bg-Alamo-White bg-Bare-Galvalume bg-Burgundy bg-Brown bg-Brite-Red bg-Burnished-Slate bg-Buckskin bg-Ash-Gray bg-Brilliant-White bg-Charcoal bg-Copper-Metallic bg-Dark-Green bg-Gallery-Blue bg-Hunter-Green bg-Ivory bg-Light-Stone bg-Matte-Black bg-Ocean bg-Pewter-Gray bg-Rustic-Red bg-Taupe bg-Tan text-white text-black'></div>
          </Container>
        )}
        </div>
      <p className='w-1/6 mx-4 my-0'> {props.quote['Date-Placed']} </p>
    </div>
  )
}


export default FullviewQuote
