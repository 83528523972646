import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import {useNavigate} from 'react-router-dom'; 
import QuickviewQuote from '../QuickviewQuote/QuickviewQuote';
import QuickviewHeaders from '../QuickviewHeaders/QuickviewHeaders';
import { useEffect } from 'react';
import { FetchQuotesCall } from './../apiCalls';
import { useState } from 'react';
import SyncLoader from "react-spinners/SyncLoader";

function Dashboard(props) {
  const navigate = useNavigate();

  useEffect( () => {
    if(!window.email){
      navigate("/Login")
    }
  })

  useEffect( () => {
    fetchQuotes();
  }, [])

  const [isLoading, setIsLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);

  const fetchQuotes = async() => {
    if(!isLoading){
      let payload = {
        'VendorEmail':window.email
      }

      setIsLoading(true);
      let quotesResp = await(FetchQuotesCall(payload));
      console.log(quotesResp);
      setQuotes(quotesResp.quotes);
      window.quotes = quotesResp.quotes
      setIsLoading(false);
  } else {return}

  }
  

  return (
    <div className='flex container flex-col lg:flex-row items-center lg:justify-center lg:mt-12 p-5 lg:p-0 lg:items-stretch justify-items-stretch'>
      <Container title="Current Listing Info" className="lg:max-w-md">
        <p>Listing Name</p>
        <b className='mb-5'>{window.name}</b>
        <p>Email</p>
        <b className='mb-5'>{window.email}</b>
        <p>Phone Number</p>
        <b className='mb-5'>{window.phone}</b>
        <p>Address</p>
        <b className='mb-5'>{window.street + " " + window.city + ", " + window.state + " " + window.zip}</b>
        <p>Sales Radius</p>
        <b className='mb-5'>{window.salesRadius + " Miles"}</b>
        <Button title="" onClick={() => GoToRoute("Dashboard/EditListing")}>Edit Listing</Button>
      </Container>
      <div className='flex flex-col items-center w-full lg:basis-3/4'>
        <Container title="Recent Quotes" className="hidden sm:flex sm:flex-col">
          {quotes.length > 0 && (<QuickviewHeaders/>)}
          {isLoading ? <div className='flex justify-center'><SyncLoader color='#4587c7'/></div>: (
            <div>
              {quotes.length == 0 && (<Heading text="No quotes to show at the moment..."/>)}
              {quotes.length > 0 && (<QuickviewQuote email={quotes[0].UserEmail} width={JSON.parse(quotes[0].Dimensions)[1]} length={JSON.parse(quotes[0].Dimensions)[0]} height={JSON.parse(quotes[0].Dimensions)[2]} dateRequested={quotes[0]['Date-Placed']}/>)}
              {quotes.length > 1 && (<QuickviewQuote email={quotes[1].UserEmail} width={JSON.parse(quotes[1].Dimensions)[1]} length={JSON.parse(quotes[1].Dimensions)[0]} height={JSON.parse(quotes[1].Dimensions)[2]} dateRequested={quotes[1]['Date-Placed']}/>)}
              {quotes.length > 2 && (<QuickviewQuote email={quotes[2].UserEmail} width={JSON.parse(quotes[2].Dimensions)[1]} length={JSON.parse(quotes[2].Dimensions)[0]} height={JSON.parse(quotes[2].Dimensions)[2]} dateRequested={quotes[2]['Date-Placed']}/>)}
            </div>
          )}
          {!isLoading && quotes.length > 0 && (<Button title="" onClick={() => GoToRoute("Dashboard/Quotes")}>View More Quotes</Button>)}
        </Container>
        <div className='flex flex-col md:flex-row flex-1 md:basis-full items-center w-full lg:items-stretch'>
          <Container title="Monthly Quotes" className="md:ml-0">
            <h1 className='text-center font-mont font-semibold text-9xl'>{window.quotesThisMonth}</h1>
          </Container>
          <Container title="Total Quotes" className="md:mr-0">
            <h1 className='text-center font-mont font-semibold text-9xl'>{window.totalQuotes}</h1>
          </Container>
        </div>
      </div>
    </div>
  )
  function GoToRoute(r) {
    navigate('/' + r)
  }
}


export default Dashboard
