import React from 'react'

function QuickviewQuote(props) {
  return (
    <div className='w-full flex flex-row justify-between text-dab-grey p-5 font-mont text-xl'>
      <p className='basis-1/5'>{props.email}</p>
      <p>{props.width + "'"}</p>
      <p>{props.length + "'"}</p>
      <p>{props.height + "'"}</p>
      <p>{props.dateRequested}</p>
    </div>
  )
}


export default QuickviewQuote
