import React from 'react'

function Container(props) {
  return (
    <div className={'container m-5 ' + props.className}>
        <div className='flex grow justify-center bg-dab-blue rounded-t-3xl text-white p-5 font-mont text-3xl font-semibold '>{/*Header*/}
            <h1 className='break-keep'>{props.title}</h1>
        </div>
        <div className='flex grow flex-col items-stretch justify-center rounded-b-3xl bg-white shadow-lg p-5 font-mont text-dab-grey font-medium'>{/*Body*/}
            {props.children}
        </div>
    </div>
  )
}


export default Container
