import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import {useNavigate} from 'react-router-dom';
import { useState } from 'react';
import Error from '../Error/Error';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
import { CheckAccountExists } from './../apiCalls';
import SyncLoader from "react-spinners/SyncLoader";

function CreateAccount(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  const [message, setMessage] = useState("");

  const CheckExists = async() => {
    if(!isLoading) {
      let payload = {
        "Vendor-Email": window.email
      }
      setIsLoading(true);
      let checkStatus = await(CheckAccountExists(payload));
      console.log(checkStatus);
      setIsLoading(false);
      if(checkStatus.Status != "Account Exists"){
        window.email = checkStatus.Status;
        GoToRoute("CreateAccount/PaymentInfo")
      } else if(checkStatus.Status == 'Account Exists'){
        setInvalid(true);
        setMessage("Account Exists. Please login instead.");
      } else {
        setInvalid(true);
        setMessage("Something went wrong. Please try again.");
      }
    }
  }

  const navigate = useNavigate();
  return (
    <div className='flex container sm:items-center sm:justify-center sm:mt-20'>
      <Container title="Create Account" className="sm:max-w-4xl">
        {isInvalid && (<Error text={message}/>)}
        <Heading text="Business Information"/>
        <Input id="businessName" label="Business Name"/>
        <Input id="email" label="Email"/>
        <Input type="password" id="password" label="Password"/>
        <Input type="password" id="confPassword" label="Confirm Password"/>
        <Button title="" onClick={() => ValidateInputs()}>{isLoading ? <SyncLoader color='white'/> : "Next"}</Button>
        <p className='underline text-center hover:cursor-pointer' onClick={() => GoToRoute("Login")}>
          Or Login
        </p>
      </Container>
    </div>
  )
  function ValidateInputs() {
    let inptEmail = document.getElementById("email");
    let inptBusiness = document.getElementById("businessName")
    let inptPassword = document.getElementById("password");
    let inptConfPassword = document.getElementById("confPassword");

    if(inptEmail.value == "" || inptPassword.value == "" || inptBusiness.value == "" || inptConfPassword.value == ""){
      setInvalid(true);
      setMessage("Fields cannot be empty.");
      return;
    }

    if(inptPassword.value != inptConfPassword.value){
      setInvalid(true);
      setMessage("Passwords do not match.");
      return;
    }

    if(inptPassword.value.length < 8) {
      setInvalid(true);
      setMessage("Password must be at least 8 characters long.");
      return;
    }

    SetValues();
  }

  function SetValues(){
    window.businessName = document.getElementById("businessName").value
    window.email = document.getElementById("email").value
    window.password = document.getElementById("password").value

    CheckExists();
  }


  function GoToRoute(r) {
    navigate('/' + r)
  }
}


export default CreateAccount
