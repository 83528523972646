import Container from '../Container/Container';
import CreateAccount from '../CreateAccount/CreateAccount';
import Header from '../Header/Header';
import Input from '../Input/Input'
import ListingInformation from '../ListingInformation/ListingInformation';
import Login from '../Login/Login';
import PaymentInformation from '../PaymentInformation/PaymentInformation';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Quotes from '../Quotes/Quotes';
import EditListing from '../EditListing/EditListing';

function App() {
  return (
    <div>
      <Header/>
      <div className='flex justify-center items-center m-auto'>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login/>}/>
              <Route path="/Login" element={<Login/>}/>
              <Route path="/CreateAccount" element={<CreateAccount/>}/>
              <Route path="/CreateAccount/PaymentInfo" element={<PaymentInformation/>}/>
              <Route path="/CreateAccount/PaymentInfo/ListingInformation" element={<ListingInformation/>}/>
              <Route path="/Dashboard" element={<Dashboard/>}/>
              <Route path="/Dashboard/Quotes" element={<Quotes/>}/>
              <Route path="/Dashboard/EditListing" element={<EditListing/>}/>
            </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
