const mode = "";
export const LoginCall = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
  
    let data = await fetch(
      'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/login',
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };

  export const CheckAccountExists = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
  
    let data = await fetch(
      'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/check-account-exists',
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };

  export const FetchQuotesCall = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
  
    let data = await fetch(
      'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/fetch-quotes',
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };

  export const CreateListingAccount = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
    let url = 'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/' + mode + 'create-listing-account'
    console.log(url);
    let data = await fetch(
      url,
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };


  export const CreateListing = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
    let data = await fetch(
      'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/' + mode + 'create-listing',
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };

  export const EditListingCall = async (payload) => {
    let stringifiedBody = JSON.stringify(payload);
  
    let requestOptions = {
      method: 'POST',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Content-Type": "application/json",
      body: stringifiedBody,
      redirect: 'follow',
    };
    let data = await fetch(
      'https://uv14zuvfg2.execute-api.us-east-2.amazonaws.com/Prod/edit-listing',
      requestOptions
    );
  
    let json = await data.json();
  
    //console.log(json);
  
    return json;
  };