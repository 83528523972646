import React from 'react'

function Heading(props) {
  return (
    <div className=''>
        <h1 className='flex justify-start text-dab-grey font-mont font-medium text-3xl mb-1'>{props.text}</h1>
    </div>
  )
}


export default Heading