import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import {useNavigate} from 'react-router-dom'; 
import {LoginCall} from '../apiCalls';
import { useState } from 'react';
import SyncLoader from "react-spinners/SyncLoader";
import Error from '../Error/Error';
import { doc } from 'prettier';
import QuoteHeaders from '../QuoteHeaders/QuoteHeaders';
import FullviewQuote from '../FullviewQuote/FullviewQuote';

function Quotes(props) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setInvalid] = useState(false);
  let quote = {
    "Roof": "['5:12', '24 in', '24 in']",
    "Date-Placed": "12 - 01 - 2022",
    "Cupolas": "[2, 48]",
    "OverheadDoors": "[0, 0, 2, 0]",
    "QuoteID": "280e0398-390a-4ecf-ae91-4ba393365a9a",
    "Dimensions": "[40, 30, 10]",
    "DAB-Listing-Email": "kyle@westgrove.tech",
    "SlidingDoors": "[0, 0, 0, 0]",
    "Windows": "[2, 0, 0, 0]",
    "WindowDimensions": "{'North': [{'Dimensions': '48 in x 36 in', 'Qty': 2}], 'West': [], 'South': [], 'East': []}",
    "WalkDoorDimensions": "{'North': [], 'West': [], 'South': [], 'East': [{'Dimensions': '36 in x 84 in', 'Qty': 1}]}",
    "ImageLink": "https://wt-publicly-hosted-media.s3.us-east-2.amazonaws.com/NoImage.png",
    "UserEmail": "mijaky42@gmail.com",
    "SlidingDoorDimensions": "{'North': [], 'West': [], 'South': [], 'East': []}",
    "Colors": "['Buckskin', 'Pewter Gray', 'Pewter Gray', 'Pewter Gray', 'Pewter Gray', 'Pewter Gray', 'Pewter Gray', 'Brilliant White']",
    "WalkDoors": "[0, 0, 0, 1]",
    "OverheadDoorDimensions": "{'North': [], 'West': [], 'South': [{'Dimensions': '108 in x 96 in', 'Qty': 2}], 'East': []}"
  }
  //window.quotes
  function CreateQuotes() {
    return window.quotes.map(quote => {
      return <FullviewQuote quote={quote}/>
    })
  }
  return (
    <div className='flex container sm:items-center sm:justify-center sm:mt-20 max-w-full'>
      
      <Container title="Recent Quotes" className="sm:max-w-full">
        <QuoteHeaders/>
       {CreateQuotes()}
      </Container>
    </div>
  )

  function GoToRoute(r) {
    navigate('/' + r)
  }

}


export default Quotes
