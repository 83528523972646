import React from 'react'

function QuoteHeaders(props) {
  return (
    <div className='w-full flex flex-row text-dab-grey font-mont font-semibold text-xl text-center'>
      <p className='w-1/6 mx-4 my-0'>Email</p>
      <p className='w-1/6 mx-4 my-0'>Dimensions</p>
      <p className='w-1/6 mx-4 my-0'>Roof</p>
      <p className='w-1/6 mx-4 my-0'>Windows/Doors</p>
      <p className='w-1/6 mx-4 my-0'>Colors</p>
      <p className='w-1/6 mx-4 my-0'>Date Requested</p>
    </div>
  )
}


export default QuoteHeaders
