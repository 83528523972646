import React from 'react'
import Input from '../Input/Input'
import Container from '../Container/Container';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import Error from '../Error/Error';
import {useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import SyncLoader from "react-spinners/SyncLoader";

import { EditListingCall } from './../apiCalls';

function EditListing(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [message, setMessage] = useState("");
  const Create = async() => {
    if(!isLoading) {
      let payload = {
        "Vendor-Email": window.email,
        "City": window.listingCity,
        "ImageLink": "link",
        "Name": window.listingName,
        "Phone": window.listingPhone,
        "Rating": 0,
        "State": window.listingState,
        "Street": window.listingStreet,
        "Tier": "Standard",
        "Zip": window.listingZip,
        "Distance": window.listingSalesRadius,
        "ListingID":window.listingID
      }
      setIsLoading(true);
      let editStatus = await(EditListingCall(payload));
      console.log(editStatus);
      setIsLoading(false);
      if(editStatus.Status == "Success"){
        window.name = editStatus.Name; 
        window.phone = editStatus.Phone;
        window.street = editStatus.Street;
        window.city = editStatus.City;
        window.state = editStatus.State;
        window.zip = editStatus.Zip;
        window.salesRadius = editStatus['Max-Distance'];
        navigate("/Dashboard")
      } else if(editStatus.Status == 'Account Exists'){
        setIsInvalid(true);
        setMessage("Account Exists. Please login instead.");
      } else {
        setIsInvalid(true);
        setMessage("Something went wrong. Please try again.");
      }
    }
  }
  useEffect( () => {
    if(!window.email){
      navigate("/Login")
    }
  })
  const navigate = useNavigate()
  return (
    <div className='flex container sm:items-center sm:justify-center sm:mt-20'>
      <Container title="Edit Listing" className="sm:max-w-4xl">
        {isInvalid && (<Error text={message}/>)}
        <Heading text="Listing Information"/>
        <div className='sm:flex sm:flex-row sm:space-x-3'>
          <Input id="listingName" label="Business Name" value={window.name}/>
          <Input id="listingPhone" label="Business Phone Number" value={window.phone}/>
        </div>
        <Heading text="Address"/>
        <div className='sm:flex sm:flex-row sm:space-x-3'>
          <Input id="listingStreet" label="Street" className="sm:basis-full" value={window.street}/>
          <Input id="listingCity" label="City" className="sm:basis-96" value={window.city}/>
          <div className='flex flex-row space-x-3'>
            <Input id="listingState" label="State" className="sm:basis-32 sm:grow-0" value={window.state}/>
            <Input id="listingZip" label="Zip" className="sm:basis-32 sm:grow-0" value={window.zip}/>
          </div>  
        </div>
        <Heading text="Sales Radius"/>
        <p>* This will be used to determine if you can service the customer</p>
        <div className='flex flex-row justify-center items-center space-x-3'>
          <Input id="listingSalesRadius" value={window.salesRadius}/>
          <Heading text="Miles"/>
        </div>
        <Button onClick={() => ValidateInputs()} title="">{isLoading ? <SyncLoader color='white'/> : "Submit"}</Button>
      </Container>
    </div>
  )

  function ValidateInputs() {
    let inptListingName = document.getElementById("listingName");
    let inptPhone = document.getElementById("listingPhone");
    let inptStreet = document.getElementById("listingStreet");
    let inptCity = document.getElementById("listingCity");
    let inptState = document.getElementById("listingState");
    let inptZip = document.getElementById("listingZip");
    let inptSalesRadius = document.getElementById("listingSalesRadius");

    if (inptListingName.value == "" || inptPhone.value == "" || inptStreet.value == "" || inptCity.value == "" || inptState.value == "" || inptZip.value == "" || inptSalesRadius.value == ""){
      setIsInvalid(true);
      setMessage("Fields cannot be empty.");
      return;
    }

    SetValues();
  }

  function SetValues(){
    window.listingName = document.getElementById("listingName").value
    window.listingPhone = document.getElementById("listingPhone").value
    window.listingStreet = document.getElementById("listingStreet").value
    window.listingCity = document.getElementById("listingCity").value
    window.listingState = document.getElementById("listingState").value
    window.listingZip = document.getElementById("listingZip").value
    window.listingSalesRadius = document.getElementById("listingSalesRadius").value
    
    Create();
  }


  function GoToRoute(r) {
    navigate('/' + r)
  }
}


export default EditListing
