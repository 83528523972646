import React from 'react'

function Error(props) {
  return (
    <div className=''>
        <p className='font-mont font-medium text-lg text-rose-500 bg-rose-100 rounded-lg p-2 mb-2'>{props.text}</p>
    </div>
  )
}


export default Error