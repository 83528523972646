import React from 'react'

function QuickviewHeaders(props) {
  return (
    <div className='w-full flex flex-row justify-between text-dab-grey p-5 font-mont font-semibold text-xl'>
      <p className='basis-1/4'>Email</p>
      <p>Width</p>
      <p>Length</p>
      <p>Height</p>
      <p>Date Requested</p>
    </div>
  )
}


export default QuickviewHeaders
